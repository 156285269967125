import React from "react";
import "./style.css";
import "./responsive.css";
import Header from "./Header";
import Footer from "./Footer";

export default function PrivacyPolicy() {
  return (
    <div>
      <Header />

      <main>
        <div className="page-header">
          <div className="page-header-wrap">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1879.8 219"
              enableBackground="new 0 0 1879.8 219"
              xmlSpace="preserve"
            >
              <polygon
                className="shade-path1"
                opacity="0.3"
                fill="#FFFFFF"
                points="0,102.5 1209.9,209.5 1879.8,0 1879.8,219 0,219"
              />
              <polygon
                className="shade-path2"
                fill="#F6F4FE"
                points="0,142.5 1209.9,199.5 1879.8,80 1879.8,219 0,219"
              />
            </svg>

            <div className="container">
              <h2 className="page-header-title " itemProp="headline">
                PRIVACY POLICY
              </h2>
              <nav className="site-breadcrumbs ">
                <ol className="trail-items">
                  <li>
                    <a href="/" rel="home" aria-label="Home">
                      <i className="fa-solid fa-house"></i>
                    </a>
                    <i className="fa-solid fa-chevron-right me-2"></i>
                  </li>
                  <li>
                    <a href="/">
                      <span>PRIVACY POLICY</span>
                    </a>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>

        <div className="pt-3 pt-lg-5  pages">
          <div className="container">
            <h4 className="m-0 mb-lg-1">
              PRIVACY <span>POLICY</span>
            </h4>
            <p className="small text-center mb-3 mb-lg-5">
              Last updated <span>December 11, 2024</span>
            </p>
            <div className="agreement mb-3">
              <h5>AGREEMENT TO OUR PRIVACY POLICY</h5>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <p>
                    This Privacy Notice for Vera, The Good Rep LLC  ({" "}
                    <span className="fw-bold">"we," "us,"</span> or{" "}
                    <span className="fw-bold">"our"</span>), describes how
                    and why we might access, collect, store, use, and/or share  (
                    <span className="fw-bold">"process"</span>) your personal
                    information when you use our services (
                    <span className="fw-bold">"Services"</span>), including when you:
                  </p>
                  <ul className="accordion-list">
                    <li>
                      <p>
                        Visit our website at{" "}
                        <a href="https://veragoodrep.com">
                          https://veragoodrep.com
                        </a>
                        , or any website of ours that links to this Privacy Notice
                      </p>
                    </li>
                    <li>
                      <p>
                        Download and use our mobile application (Vera, The Good Rep),
                        or any other application of ours that links to this Privacy Notice
                      </p>
                    </li>
                    <li>
                      <p>
                        Use Vera, The Good Rep. Vera, The Good Rep uses AI realtime information
                        to get your clients up front, transparent pricing, questions answered in
                        real time, and funnels leads directly to your application for you to close.
                      </p>
                    </li>
                    <li>
                      <p>
                        Engage with us in other related ways, including any sales, marketing, or events
                      </p>
                    </li>
                  </ul>
                </li>
                <li className="list-group-item">
                  <p>
                    <span className="fw-semibold">Questions or concerns?</span>
                    {" "}Reading this Privacy Notice will help you understand your privacy
                    rights and choices. We are responsible for making decisions about how
                    your personal information is processed. If you do not agree with our
                    policies and practices, please do not use our Services. If you still have
                    any questions or concerns, please contact us
                    at Info@veragoodrep.com.<br />
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="haveanyquestion">
            <div className="container">
              <h5>SUMMARY OF KEY POINTS</h5>
              <div className="accordion" id="accordionExample2">
                <p className="fw-semibold">
                  This summary provides key points from our Privacy Notice, but you can
                  find out more details about any of these topics by clicking the link
                  following each key point or by using our <a href="/">table of contents </a>below to find the section you are looking for.
                </p>
                <div className="accordion-item">


                  <h2 className="accordion-header">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#acollapseOne"
                      aria-expanded="true"
                      aria-controls="acollapseOne"
                    >
                      What personal information do we process?
                    </button>
                  </h2>
                  <div
                    id="acollapseOne"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#accordionExample2"
                  >
                    <div className="accordion-body">
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                          <p>
                            When you visit, use, or navigate our Services, we may
                            process personal information depending on how you interact
                            with us and the Services, the choices you make, and the products
                            and features you use. Learn more about{" "}
                            <a href="#collapseOne">
                              {" "}
                              personal information you disclose to us.
                            </a>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#acollapseTwo"
                      aria-expanded="false"
                      aria-controls="acollapseTwo"
                    >
                      Do we process any sensitive personal information?
                    </button>
                  </h2>
                  <div
                    id="acollapseTwo"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample2"
                  >
                    <div className="accordion-body">
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                          <p>
                            Some of the information may be considered "special" or "sensitive"
                            in certain jurisdictions, for example your racial or ethnic origins,
                            sexual orientation, and religious beliefs. We do not process sensitive
                            personal information.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#acollapseTwo"
                      aria-expanded="false"
                      aria-controls="acollapseTwo"
                    >
                      Do we collect any information from third parties?
                    </button>
                  </h2>
                  <div
                    id="acollapseTwo"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample2"
                  >
                    <div className="accordion-body">
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                          <p>
                            We do not collect any information from third parties.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#acollapse3"
                      aria-expanded="false"
                      aria-controls="acollapse3"
                    >
                      How do we process your information?
                    </button>
                  </h2>
                  <div
                    id="acollapse3"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample2"
                  >
                    <div className="accordion-body">
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                          <p>
                            We process your information to provide, improve, and administer our
                            Services, communicate with you, for security and fraud prevention,
                            and to comply with law. We may also process your information for other
                            purposes with your consent. We process your information only when we
                            have a valid legal reason to do so. Learn more about
                            <a href="#collapseOne">
                              {" "}
                              how we process your information.
                            </a>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#acollapse5"
                      aria-expanded="false"
                      aria-controls="acollapse5"
                    >
                      In what situations and with which types of parties do we share personal information?
                    </button>
                  </h2>
                  <div
                    id="acollapse5"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample2"
                  >
                    <div className="accordion-body">
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                          <p>
                            We may share information in specific situations and
                            with specific third parties. Learn more about{" "}
                            <a href="#personalinfo">
                              when and with whom we share your personal
                              information.
                            </a>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#acollapse6"
                      aria-expanded="false"
                      aria-controls="acollapse6"
                    >
                      How do we keep your information safe?
                    </button>
                  </h2>
                  <div
                    id="acollapse6"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample2"
                  >
                    <div className="accordion-body">
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                          <p>
                            We have adequate organizational and technical processes and
                            procedures in place to protect your personal information.
                            However, no electronic transmission over the internet or information
                            storage technology can be guaranteed to be 100% secure, so we cannot
                            promise or guarantee that hackers, cybercriminals, or other unauthorized
                            third parties will not be able to defeat our security and improperly collect,
                            access, steal, or modify your information. Learn more about {' '}
                            <a href="#infosafe">
                              how we keep your information safe.
                            </a>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#acollapse7"
                      aria-expanded="false"
                      aria-controls="acollapse7"
                    >
                      What are your rights?
                    </button>
                  </h2>
                  <div
                    id="acollapse7"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample2"
                  >
                    <div className="accordion-body">
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                          <p>
                            Depending on where you are located geographically, the applicable
                            privacy law may mean you have certain rights regarding your personal
                            information. Learn more about {" "}
                            <a href="#privacyrights">your privacy rights.</a>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#acollapse8"
                      aria-expanded="false"
                      aria-controls="acollapse8"
                    >
                      How do you exercise your rights?
                    </button>
                  </h2>
                  <div
                    id="acollapse8"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample2"
                  >
                    <div className="accordion-body">
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                          <p>
                            The easiest way to exercise your rights is by
                            submitting a{" "}
                            <a href="https://app.termly.io/notify/bcb8e170-7a4b-4f1f-8cdc-d5b014924020">
                              data subject access request
                            </a>, or by contacting us. We will
                            consider and act upon any request in
                            accordance with applicable data protection laws.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <p>
                Want to learn more about what we do with any information we collect?{" "}<a href="/">Review the privacy notice in full.</a>
              </p>
            </div>
          </div>
          <div className="container py-3">
            <h5>Have any questions?</h5>
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    WHAT INFORMATION DO WE COLLECT?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse "
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <ul className="list-group list-group-flush mb-3">
                      <h6>
                        <strong>Personal information you disclose to us</strong>
                      </h6>
                      <li className="list-group-item">
                        <p className="fst-italic">
                          <span className="fw-semibold">In Short:</span>{" "}We collect personal information that you provide to us.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>
                          We collect personal information that you voluntarily provide
                          to us when you register on the Services, express an interest in
                          obtaining information about us or our products and Services, when
                          you participate in activities on the Services, or otherwise when you
                          contact us.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>
                          <span className="fw-semibold">
                            Personal Information Provided by You.
                          </span>
                          The personal information that we collect depends on the
                          context of your interactions with us and the Services, the
                          choices you make, and the products and features you use.
                          The personal information we collect may include the following:
                        </p>
                        <ul className="accordion-list mb-3">
                          <li>
                            <p>names</p>
                          </li>
                          <li>
                            <p>phone numbers</p>
                          </li>
                          <li>
                            <p>email addresses</p>
                          </li>
                          <li>
                            <p>mailing addresses</p>
                          </li>
                          <li>
                            <p>job titles</p>
                          </li>
                          <li>
                            <p>usernames</p>
                          </li>
                          <li>
                            <p>passwords</p>
                          </li>
                          <li>
                            <p>contact or authentication data</p>
                          </li>
                          <li>
                            <p>billing addresses</p>
                          </li>
                          <li>
                            <p>debit/credit card numbers</p>
                          </li>
                          <li>
                            <p>contact preferences</p>
                          </li>
                        </ul>
                      </li>
                      <li className="list-group-item">
                        <p>
                          <span className="fw-semibold">
                            Sensitive Information.
                          </span>
                          We do not process sensitive information.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>
                          <span className="fw-semibold">Payment Data.</span>
                          We may collect data necessary to process your payment if you
                          choose to make purchases, such as your payment instrument number,
                          and the security code associated with your payment instrument. All
                          payment data is handled and stored by In App Purchase.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>
                          <span className="fw-semibold">Application Data.</span>
                          If you use our application(s), we also may collect the following information if you choose to provide us with access or permission:</p>
                        <ul className="accordion-list">
                          <li>
                            <p>
                              <span className="fst-italic">
                                Mobile Device Access.
                              </span>{" "}
                              We may request access or permission
                              to certain features from your mobile device, including your
                              mobile device's calendar, sms messages, and other features. If you
                              wish to change our access or permissions, you may
                              do so in your device's settings.
                            </p>
                          </li>
                          <li>
                            <p>
                              <span className="fst-italic">
                                Mobile Device Data.
                              </span>{" "}
                              We automatically collect
                              device information (such as your mobile device ID,
                              model, and manufacturer), operating system, version
                              information and system configuration information, device
                              and application identification numbers, browser type and
                              version, hardware model Internet service provider and/or mobile
                              carrier, and Internet Protocol (IP) address (or proxy server).
                              If you are using our application(s), we may also collect information
                              about the phone network associated with your mobile device, your mobile
                              device’s operating system or platform, the type of mobile device you use,
                              your mobile device’s unique device ID, and information about the features of our application(s) you accessed.
                            </p>
                          </li>
                          <li>
                            <p>
                              <span className="fst-italic">
                                Push Notifications.
                              </span>{" "}
                              We may request to send you push notifications
                              regarding your account or certain features of the application(s).
                              If you wish to opt out from receiving these types of communications,
                              you may turn them off in your device's settings.
                            </p>
                          </li>
                        </ul>
                      </li>
                      <li className="list-group-item">
                        <p>
                          This information is primarily needed to maintain the security and
                          operation of our application(s), for troubleshooting, and for our
                          internal analytics and reporting purposes.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>
                          All personal information that you provide to us must be true,
                          complete, and accurate, and you must notify us of any changes
                          to such personal information.
                        </p>
                      </li>
                    </ul>
                    <ul className="list-group list-group-flush mb-3">
                      <h6>
                        <strong>Information automatically collected</strong>
                      </h6>
                      <li className="list-group-item">
                        <p className="fst-italic">
                          <span className="fw-bold">In Short:</span>{" "}Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>
                          We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>
                          Like many businesses, we also collect information through cookies and similar technologies. You can find out more
                          about this in our Cookie Notice: __________.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>The information we collect includes:</p>
                        <ul className="accordion-list mb-3">
                          <li>
                            <p>
                              {" "}
                              <span className="fst-italic">
                                Log and Usage Data.
                              </span>{" "}
                              Log and usage data is service-related, diagnostic, usage,
                              and performance information our servers automatically collect
                              when you access or use our Services and which we record in log
                              files. Depending on how you interact with us, this log data may include
                              your IP address, device information, browser type, and settings and
                              information about your activity in the Services (such as the date/time
                              stamps associated with your usage, pages and files viewed, searches, and
                              other actions you take such as which features you use), device event
                              information (such as system activity, error reports (sometimes called
                              "crash dumps"), and hardware settings).
                            </p>
                          </li>
                          <li>
                            <p>
                              {" "}
                              <span className="fst-italic">
                                Device Data.
                              </span>{" "}
                              We collect device data such as information about your computer,
                              phone, tablet, or other device you use to access the Services.
                              Depending on the device used, this device data may include information
                              such as your IP address (or proxy server), device and application
                              identification numbers, location, browser type, hardware model, Internet
                              service provider and/or mobile carrier, operating system, and system
                              configuration information.
                            </p>
                          </li>
                          <li>
                            <p>
                              {" "}
                              <span className="fst-italic">
                                Location Data.
                              </span>{" "}
                              We collect location data such as information about your device's
                              location, which can be either precise or imprecise. How much information
                              we collect depends on the type and settings of the device you use to access
                              the Services. For example, we may use GPS and other technologies to collect
                              geolocation data that tells us your current location (based on your IP address).
                              You can opt out of allowing us to collect this information either by refusing access
                              to the information or by disabling your Location setting on your device. However, if
                              you choose to opt out, you may not be able to use certain aspects of the Services.
                            </p>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="accordion-item" id="yourinfo">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    HOW DO WE PROCESS YOUR INFORMATION?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <ul className="list-group list-group-flush mb-3">
                      <li className="list-group-item">
                        <p className="fst-italic">
                          <span className="fw-semibold">In Short:</span>{" "}We
                          process your information to provide, improve, and
                          administer our Services, communicate with you, for
                          security and fraud prevention, and to comply with
                          law. We may also process your information for other
                          purposes with your consent.
                        </p>
                      </li>To facilitate account creation and authentication and otherwise manage user accounts
                      <li className="list-group-item">
                        <p className="fw-semibold">
                          We process your personal information for a variety of reasons, depending on how you interact with our Services, including:
                        </p>
                        <ul className="accordion-list mb-3">
                          <li>
                            <p>
                              {" "}
                              <span className="fw-semibold">
                                To facilitate account creation and
                                authentication and otherwise manage user
                                accounts.
                              </span>{" "}
                              We may process your information so you can create and log in to your account, as well as keep your account in working order.
                            </p>
                          </li>
                          <li>
                            <p>
                              {" "}
                              <span className="fw-semibold">
                                To deliver and facilitate delivery of services to the user.
                              </span>{" "}
                              We may process your information to provide you with the requested service.
                            </p>
                          </li>
                          <li>
                            <p>
                              {" "}
                              <span className="fw-semibold">
                                To respond to user inquiries/offer support to users
                              </span>{" "}
                              We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service.
                            </p>
                          </li>
                          <li>
                            <p>
                              {" "}
                              <span className="fw-semibold">
                                To send administrative information to you
                              </span>{" "}
                              We may process your information to send you details about our products and services, changes to our terms and policies, and other similar information.
                            </p>
                          </li>
                          <li>
                            <p>
                              {" "}
                              <span className="fw-semibold">
                                To fulfill and manage your orders.
                              </span>{" "}
                              We may process your information to fulfill and manage your orders, payments, returns, and exchanges made through the Services.
                            </p>
                          </li>
                          <li>
                            <p>
                              {" "}
                              <span className="fw-semibold">
                                To enable user-to-user communications.
                              </span>{" "}
                              We may process your information if you choose to use any of our offerings that allow for communication with another user.
                            </p>
                          </li>
                          <li>
                            <p>
                              {" "}
                              <span className="fw-semibold">
                                To request feedback
                              </span>{" "}
                              We may process your information when necessary to request feedback and to contact you about your use of our Services.
                            </p>
                          </li>
                          <li>
                            <p>
                              {" "}
                              <span className="fw-semibold">
                                To send you marketing and promotional communications.
                              </span>{" "}
                              We may process the personal information you send
                              to us for our marketing purposes, if this is in
                              accordance with your marketing preferences. You
                              can opt out of our marketing emails at any time.
                              For more information, see{" "}
                              <a href="/">"WHAT ARE YOUR PRIVACY RIGHTS?"</a>{" "}
                              below.
                            </p>
                          </li>
                          <li>
                            <p>
                              {" "}
                              <span className="fw-semibold">
                                To deliver targeted advertising to you.
                              </span>{" "}
                              We may process your information to develop and display personalized content and advertising tailored to your interests, location, and more. For more information see our Cookie Notice: __________.
                            </p>
                          </li>
                          <li>
                            <p>
                              {" "}
                              <span className="fw-semibold">
                                To post testimonials.
                              </span>{" "}
                              We post testimonials on our Services that may contain personal information.
                            </p>
                          </li>
                          <li>
                            <p>
                              {" "}
                              <span className="fw-semibold">
                                To protect our Services.
                              </span>{" "}
                              We may process your information as part of our efforts to keep our Services safe and secure, including fraud monitoring and prevention.
                            </p>
                          </li>
                          <li>
                            <p>
                              {" "}
                              <span className="fw-semibold">
                                To evaluate and improve our Services, products, marketing, and your experience
                              </span>{" "}
                              We may process your information when we believe it is necessary to identify
                              usage trends, determine the effectiveness of our promotional campaigns, and to
                              evaluate and improve our Services, products, marketing, and your experience.
                            </p>
                          </li>
                          <li>
                            <p>
                              {" "}
                              <span className="fw-semibold">
                                To identify usage trends
                              </span>{" "}
                              We may process information about how you use our Services to better understand how they are being used so we can improve them.
                            </p>
                          </li>
                          <li>
                            <p>
                              {" "}
                              <span className="fw-semibold">
                                To determine the effectiveness of our marketing and promotional campaigns
                              </span>{" "}
                              We may process your information to better understand how to provide marketing and promotional campaigns that are most relevant to you.
                            </p>
                          </li>
                          <li>
                            <p>
                              {" "}
                              <span className="fw-semibold">
                                To comply with our legal obligations.
                              </span>{" "}
                              We may process your information to comply with our
                              legal obligations, respond to legal requests, and
                              exercise, establish, or defend our legal rights.
                            </p>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="accordion-item" id="personalinfo">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <ul className="list-group list-group-flush">

                      <li className="list-group-item">
                        <p className="fst-italic">
                          <span className="fw-bold">In Short:</span>{" "}We may
                          share information in specific situations described in
                          this section and/or with the following third parties.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>
                          <span className="fw-bold">Vendors, Consultants, and Other Third-Party Service Providers.</span> We may
                          share your data with <span className="fw-bold">("third-party")</span> vendors, service providers, contractors, or agents ("third parties")
                          who perform services for us or on our behalf and require access to such information to do that work. We
                          have contracts in place with our third parties, which are designed to help safeguard your personal information.
                          This means that they cannot do anything with your personal information unless we have instructed them to do it.
                          They will also not share your personal information with any organization apart from us. They also commit to protect
                          the data they hold on our behalf and to retain it for the period we instruct.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>The categories of third parties we may share personal information with are as follows:</p>
                        <ul className="accordion-list mb-3">
                          <li>
                            <p>AI Platforms</p>
                          </li>
                          <li>
                            <p>Sales & Marketing Tools</p>
                          </li>
                          <li>
                            <p>User Account Registration & Authentication Services</p>
                          </li>
                          <li>
                            <p>Website Hosting Service Providers</p>
                          </li>
                          <li>
                            <p>Performance Monitoring Tools</p>
                          </li>
                          <li>
                            <p>Data Storage Service Providers</p>
                          </li>
                          <li>
                            <p>Data Analytics Services</p>
                          </li>
                          <li>
                            <p>Communication & Collaboration Tools</p>
                          </li>
                          <li>
                            <p>Cloud Computing Services
                            </p>
                          </li>
                          <li>
                            <p>Affiliate Marketing Programs
                            </p>
                          </li>
                          <li>
                            <p>Testing Tools
                            </p>
                          </li>
                          <li>
                            <p>Order Fulfillment Service Providers</p>
                          </li>
                          <li>
                            <p>Payment Processors</p>
                          </li>

                        </ul>
                      </li>
                      <li className="list-group-item">
                        <p>
                          We also may need to share your personal information in the following situations:
                        </p>
                        <ul className="accordion-list">
                          <li>
                            <p>
                              <span className="fw-semibold">
                                Business Transfers.
                              </span>{" "}
                              We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
                            </p>
                          </li>
                          <li>
                            <p>
                              <span className="fw-semibold">
                                Business Partners.
                              </span>{" "}
                              We may share your information with our business
                              partners to offer you certain products, services,
                              or promotions.
                            </p>
                          </li>
                          <li>
                            <p>
                              <span className="fw-semibold">
                                Affiliates.
                              </span>{" "}
                              We may share your information with our affiliates, in which case we will require those affiliates to honor this Privacy Notice. Affiliates include our parent company and any subsidiaries, joint venture partners, or other companies that we control or that are under common control with us.
                            </p>
                          </li>
                          <li>
                            <p>
                              <span className="fw-semibold">Other Users.</span>{" "}
                              When you share personal information or otherwise interact
                              with public areas of the Services, such personal information
                              may be viewed by all users and may be publicly made available
                              outside the Services in perpetuity. Similarly, other users will
                              be able to view descriptions of your activity, communicate with
                              you within our Services, and view your profile.
                            </p>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <p className="fst-italic">
                          <span className="fw-bold">In Short:</span>{" "}We may use cookies and other tracking technologies to collect and store your information.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>
                          We may use cookies and similar tracking technologies
                          (like web beacons and pixels) to gather information
                          when you interact with our Services. Some online tracking
                          technologies help us maintain the security of our Services
                          and your account, prevent crashes, fix bugs, save your preferences,
                          and assist with basic site functions.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>
                          We also permit third parties and service providers to use online
                          tracking technologies on our Services for analytics and advertising,
                          including to help manage and display advertisements, to tailor
                          advertisements to your interests, or to send abandoned shopping
                          cart reminders (depending on your communication preferences). The
                          third parties and service providers use their technology to provide
                          advertising about products and services tailored to your interests
                          which may appear either on our Services or on other websites.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>
                          To the extent these online tracking technologies are deemed to be
                          a "sale"/"sharing" (which includes targeted advertising, as defined
                          under the applicable laws) under applicable US state laws, you can opt
                          out of these online tracking technologies by submitting a request as
                          described
                          below under section
                          <a href="/">"DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?"</a>{" "}
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>
                          Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice: __________.
                        </p>
                      </li>
                    </ul>
                    <h6>
                      <strong>Google Analytics                        </strong>
                    </h6>
                    <li className="list-group-item">
                      <p>
                        We may share your information with Google Analytics to track and analyze the
                        use of the Services. The Google Analytics Advertising Features that we may use
                        include: Google Display Network Impressions Reporting. To opt out of being
                        tracked by Google Analytics across the Services,
                        visit<a href="/"> https://tools.google.com/dlpage/gaoptout</a>  .
                        You can opt out of Google Analytics Advertising Features
                        through <a href="/"> Ads Settings</a> and Ad Settings for mobile apps. Other opt
                        out means include <a href="/"> http://optout.networkadvertising.org/ </a>
                        and <a href="/">http://www.networkadvertising.org/mobile-choice </a>. For
                        more information on the privacy practices of Google, please
                        visit the <a href="/"> Google Privacy & Terms page</a>.
                      </p>
                    </li>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    DO WE OFFER ARTIFICIAL INTELLIGENCE-BASED PRODUCTS?
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <p className="fst-italic">
                          <span className="fw-bold">In Short:</span>{" "}We offer products, features,
                          or tools powered by artificial intelligence, machine learning, or
                          similar technologies.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>
                          As part of our Services, we offer products, features, or tools powered by artificial intelligence, machine learning, or similar technologies (collectively, "AI Products"). These tools are designed to enhance your experience and provide you with innovative solutions. The terms in this Privacy Notice govern your use of the AI Products within our Services.
                        </p>
                      </li>
                      <p>
                        <span className="fw-semibold">
                          Use of AI Technologies
                        </span>{" "}
                      </p>
                      <li className="list-group-item">
                        <p>
                          We provide the AI Products through third-party service providers ("AI Service Providers"),
                          including OpenAI, Amazon Web Services (AWS) AI and Google Cloud AI. As outlined in this
                          Privacy Notice, your input, output, and personal information will be shared with and processed
                          by these AI Service Providers to enable your use of our AI Products for purposes outlined in
                          <a href="/">"WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?" </a>You must not use the
                          AI Products in any way that violates the terms or policies of any AI Service Provider.
                        </p>
                      </li>
                      <li>
                        <p>
                          <span className="fw-semibold">
                            Our AI Products
                          </span>{" "}
                        </p>
                        <p>Our AI Products are designed for the following functions:</p>
                        <ul className="accordion-list mb-3">
                          <li>
                            <p>AI deployment</p>
                          </li>
                          <li>
                            <p>AI bots</p>
                          </li>
                          <li>
                            <p>AI automation</p>
                          </li>
                          <li>
                            <p>AI translation</p>
                          </li>
                          <li>
                            <p>Text analysis</p>
                          </li>
                          <li>
                            <p>Machine learning models</p>
                          </li>
                        </ul>
                        <p>
                          <span className="fw-semibold">
                            How We Process Your Data Using AI<br />
                          </span>{" "}<br />
                          All personal information processed using our AI Products is handled in line with our Privacy Notice and our agreement with third parties. This ensures high security and safeguards your personal information throughout the process, giving you peace of mind about your data's safety.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    HOW LONG DO WE KEEP YOUR INFORMATION?
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <p className="fst-italic">
                          <span className="fw-bold">In Short:</span>{" "}We keep your information for as long as
                          necessary to fulfill the purposes outlined in this Privacy Notice unless
                          otherwise required by law.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>
                          We will only keep your personal information for as long as it is
                          necessary for the purposes set out in this Privacy Notice, unless
                          a longer retention period is required or permitted by law (such as tax,
                          accounting, or other legal requirements). No purpose in this notice will
                          require us keeping your personal information for longer than the period of time in which users have an account with us.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>
                          When we have no ongoing legitimate business need to process your
                          personal information, we will either delete or anonymize such information, or,
                          if this is not possible (for example, because your personal information has been
                          stored in backup archives), then we will securely store your personal information
                          and isolate it from any further processing until deletion is possible.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="accordion-item" id="infosafe">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    HOW DO WE KEEP YOUR INFORMATION SAFE?
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <p className="fst-italic">
                          <span className="fw-bold">In Short:</span>{" "}We aim to protect your personal information through a system of organizational and technical security measures.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>
                          We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSeven"
                    aria-expanded="false"
                    aria-controls="collapseSeven"
                  >
                    DO WE COLLECT INFORMATION FROM MINORS?
                  </button>
                </h2>
                <div
                  id="collapseSeven"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <p className="fst-italic">
                          <span className="fw-bold">In Short:</span>{" "}We do not knowingly collect data from or market to children under 18 years of age.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>
                          We do not knowingly collect, solicit data from, or
                          market to children under 18 years of age, nor do we
                          knowingly sell such personal information. By using the
                          Services, you represent that you are at least 18 or that you are
                          the parent or guardian of such a minor and consent to such minor
                          dependent’s use of the Services. If we learn that personal information from
                          users less than 18 years of age has been collected, we will deactivate the account
                          and take reasonable measures to promptly delete such data from our records. If you
                          become aware of any data we may have collected from children under age 18, please c
                          ontact us {" "}
                          Info@veragoodrep.com
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="accordion-item" id="privacyrights">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseEight"
                    aria-expanded="false"
                    aria-controls="collapseEight"
                  >
                    WHAT ARE YOUR PRIVACY RIGHTS?
                  </button>
                </h2>
                <div
                  id="collapseEight"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <ul className="list-group list-group-flush mb-3">
                      <li className="list-group-item">
                        <p className="fst-italic">
                          <span className="fw-bold">In Short:</span>{" "}You may review, change, or terminate your account at any time,
                          depending on your country, province, or state of residence.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>
                          <span className="text-decoration-underline fw-semibold">
                            Withdrawing your consent:
                          </span>{" "}
                          If we are relying on your consent to process your personal information,
                          which may be express and/or implied consent depending on the applicable law,
                          you have the right to withdraw your consent at any time. You can withdraw your
                          consent at any time by contacting us by using the contact details provided in the section{" "}
                          <a href="/">
                            "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
                          </a>{" "}
                          below.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>
                          However, please note that this will not affect the lawfulness of the
                          processing before its withdrawal nor, when applicable law allows, will
                          it affect the processing of your personal information conducted in reliance
                          on lawful processing grounds other than consent.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>
                          <span className="text-decoration-underline fw-semibold">
                            Opting out of marketing and promotional
                            communications:
                          </span>{" "}
                          You can unsubscribe from our marketing and promotional
                          communications at any time by clicking on the
                          unsubscribe link in the emails that we send, replying
                          "STOP" or "UNSUBSCRIBE" to the SMS messages that we
                          send, or by contacting us using the details provided
                          in the section{" "}
                          <a href="/">
                            {" "}
                            "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
                          </a>{" "}
                          below. You will then be removed from the marketing
                          lists. However, we may still communicate with you —
                          for example, to send you service-related messages that
                          are necessary for the administration and use of your
                          account, to respond to service requests, or for other
                          non-marketing purposes.
                        </p>
                      </li>
                    </ul>
                    <ul className="list-group list-group-flush mb-3">
                      <h6>
                        <strong>Account Information</strong>
                      </h6>
                      <li className="list-group-item">
                        <p>
                          If you would at any time like to review or change the information in your account or terminate your account, you can:
                        </p>
                        <ul className="accordion-list mb-3">
                          <li>
                            <p>
                              Log in to your account settings and update your user account.
                            </p>
                          </li>
                        </ul>
                      </li>
                      <li className="list-group-item">
                        <p>
                          Upon your request to terminate your account,
                          we will deactivate or delete your account and
                          information from our active databases. However,
                          we may retain some information in our files to
                          prevent fraud, troubleshoot problems, assist with
                          any investigations, enforce our legal terms and/or comply with applicable legal requirements.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>
                          <span className="text-decoration-underline fw-semibold">
                            Cookies and similar technologies:
                          </span>{" "}
                          Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services. For further information, please see our Cookie Notice: __________.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>
                          If you have questions or comments about your privacy
                          rights, you may email us at{" "}
                          Info@veragoodrep.com.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseNine"
                    aria-expanded="false"
                    aria-controls="collapseNine"
                  >
                    CONTROLS FOR DO-NOT-TRACK FEATURES
                  </button>
                </h2>
                <div
                  id="collapseNine"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <p>
                          Most web browsers and some mobile operating systems and
                          mobile applications include a Do-Not-Track ("DNT") feature
                          or setting you can activate to signal your privacy preference
                          not to have data about your online browsing activities monitored
                          and collected. At this stage, no uniform technology standard for
                          recognizing and implementing DNT signals has been finalized.
                          As such, we do not currently respond to DNT browser signals or
                          any other mechanism that automatically communicates your choice
                          not to be tracked online. If a standard for online tracking is
                          adopted that we must follow in the future, we will inform you about
                          that practice in a revised version of this Privacy Notice.

                        </p>
                        <p>
                          California law requires us to let you know how we respond to web browser DNT signals. Because there currently is not an industry or legal standard for recognizing or honoring DNT signals, we do not respond to them at this time.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTen"
                    aria-expanded="false"
                    aria-controls="collapseTen"
                  >
                    DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                  </button>
                </h2>
                <div
                  id="collapseTen"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <p className="fst-italic">
                          <span className="fw-bold">In Short:</span>{" "}If you are a resident of California, Colorado,
                          Connecticut, Delaware, Florida, Indiana, Iowa, Kentucky, Minnesota, Montana, Nebraska,
                          New Hampshire, New Jersey, Oregon, Tennessee, Texas, Utah, or Virginia, you may have the
                          right to request access to and receive details about the personal information we maintain
                          about you and how we have processed it, correct inaccuracies, get a copy of, or delete your
                          personal information. You may also have the right to withdraw your consent to our processing
                          of your personal information. These rights may be limited in some circumstances by applicable law.
                          More information is provided below.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <strong>
                          Categories of Personal Information We Collect?
                        </strong>
                      </li>
                      <br />
                      <li className="list-group-item">
                        <p>
                          We have collected the following categories of personal
                          information in the past twelve (12) months:
                        </p>
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Category</th>
                              <th className="w-50">Examples</th>
                              <th className="text-center">Collected</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>A. Identifiers</td>
                              <td>
                                Contact details, such as real name, alias,
                                postal address, telephone or mobile contact number,
                                unique personal identifier, online identifier,
                                Internet Protocol address, email address, and account name
                              </td>
                              <td className="text-center">YES</td>
                            </tr>
                            <tr>
                              <td>
                                B. Personal information as defined in the California Customer Records statute
                              </td>
                              <td>
                                Name, contact information, education, employment, employment history, and financial information
                              </td>
                              <td className="text-center">YES</td>
                            </tr>
                            <tr>
                              <td>
                                C. Protected classification characteristics under state or federal law
                              </td>
                              <td>Gender, age, date of birth, race and ethnicity, national origin, marital status, and other demographic data</td>
                              <td className="text-center">NO</td>
                            </tr>
                            <tr>
                              <td>D. Commercial information</td>
                              <td>
                                Transaction information, purchase history, financial details, and payment information
                              </td>
                              <td className="text-center">NO</td>
                            </tr>
                            <tr>
                              <td>E. Biometric information</td>
                              <td>Fingerprints and voiceprints</td>
                              <td className="text-center">NO</td>
                            </tr>
                            <tr>
                              <td>
                                F. Internet or other similar network activity
                              </td>
                              <td>
                                Browsing history, search history, online behavior, interest data, and interactions with our and other websites, applications, systems, and advertisements
                              </td>
                              <td className="text-center">NO</td>
                            </tr>
                            <tr>
                              <td>G. Geolocation data</td>
                              <td>Device location</td>
                              <td className="text-center">NO</td>
                            </tr>
                            <tr>
                              <td>
                                H. Audio, electronic, sensory, or similar information
                              </td>
                              <td>
                                Images and audio, video or call recordings created in connection with our business activities
                              </td>
                              <td className="text-center">NO</td>
                            </tr>
                            <tr>
                              <td>
                                I. Professional or employment-related information
                              </td>
                              <td>
                                Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for a job with us
                              </td>
                              <td className="text-center">YES</td>
                            </tr>
                            <tr>
                              <td>J. Education Information</td>
                              <td>Student records and directory information</td>
                              <td className="text-center">NO</td>
                            </tr>
                            <tr>
                              <td>
                                K. Inferences drawn from collected personal information
                              </td>
                              <td>
                                Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual’s preferences and characteristics
                              </td>
                              <td className="text-center">NO</td>
                            </tr>
                            <tr>
                              <td>L. Sensitive personal Information</td>
                              <td></td>
                              <td className="text-center">NO</td>
                            </tr>
                          </tbody>
                        </table>
                      </li>
                      <li className="list-group-item">
                        <p>
                          We may also collect other personal information outside of these categories through instances where you interact with us in person, online, or by phone or mail in the context of:
                        </p>
                        <ul className="accordion-list">
                          <li>
                            <p>
                              Receiving help through our customer support channels;
                            </p>
                          </li>
                          <li>
                            <p>
                              Participation in customer surveys or contests; and
                            </p>
                          </li>
                          <li>
                            <p>
                              Facilitation in the delivery of our Services and to respond to your inquiries.
                            </p>
                          </li>
                        </ul>
                      </li><br/>
                      <li className="list-group-item">
                        <p>
                          We will use and retain the collected personal information as needed to provide the Services or for:
                        </p>
                        <ul className="accordion-list">
                          <li>
                            <p>
                              Category A - As long as the user has an account with us
                            </p>
                          </li>
                          <li>
                            <p>
                              Category B - As long as the user has an account with us
                            </p>
                          </li>
                          <li>
                            <p>
                              Category I - As long as the user has an account with us
                            </p>
                          </li>
                        </ul>
                      </li>
                      <li className="list-group-item">
                        <strong>
                          Sources of Personal Information
                        </strong>
                        <p>
                          Learn more about the sources of personal information we collect in <a href="/"> "WHAT INFORMATION DO WE COLLECT?" </a>
                        </p>
                      </li>
                      <li className="list-group-item">
                        <strong>
                          How We Use and Share Personal Information
                        </strong>
                      </li>
                      <li className="list-group-item">
                        <p>
                          Your personal information may be used in profiling and
                          automated processes that could produce legal or similarly
                          significant effects for you. Learn more about how we use
                          your personal information in the section,
                          ,{" "}
                          <a href="/">"HOW DO WE PROCESS YOUR INFORMATION?"</a>
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>
                          We collect and share your personal information through:
                        </p>
                        <ul className="accordion-list">
                          <li>
                            <p>
                              Targeting cookies/Marketing cookies
                            </p>
                          </li>
                          <li>
                            <p>
                              Social media cookies
                            </p>
                          </li>
                        </ul>
                      </li><br />
                      <li className="list-group-item">
                        <p className="fw-semibold">
                          Will your information be shared with anyone else?
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>
                          We may disclose your personal information with our
                          service providers pursuant to a written contract
                          between us and each service provider. Learn more about
                          how we disclose personal information to in the
                          section,
                          <a href="/">
                            "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
                            INFORMATION?"
                          </a>
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>
                          We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be "selling" of your personal information.
                        </p>
                        <p>
                          We have disclosed the following categories of personal information to third parties for a business or commercial purpose in the preceding twelve (12) months:
                        </p>
                        <p>
                          The categories of third parties to whom we disclosed personal information for a business or commercial purpose can be found under <a href="/"> "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?"</a>                        </p>
                      </li>
                    </ul>
                    <li className="list-group-item">
                      <p>
                        We have sold or shared the following categories of personal information to third parties in the preceding twelve (12) months:
                      </p>
                      <ul className="accordion-list">
                        <li>
                          <p>
                            Category A. Identifiers
                          </p>
                        </li>
                        <li>
                          <p>
                            Category B. Personal information as defined in the California Customer Records law
                          </p>
                        </li>
                      </ul>
                    </li><br />
                    <li className="list-group-item">
                      <p>
                        The categories of third parties to whom we sold personal information are:
                      </p>
                      <ul className="accordion-list">
                        <li>
                          <p>
                            User Account Registration & Authentication Services
                          </p>
                        </li>
                        <li>
                          <p>
                            Data Analytics Services
                          </p>
                        </li>
                        <li>
                          <p>
                            AI Platforms
                          </p>
                        </li>
                        <li>
                          <p>
                            Affiliate Marketing Programs
                          </p>
                        </li>
                      </ul>
                    </li><br />

                    <li className="list-group-item">
                      <p>
                        The categories of third parties to whom we shared personal information with are:
                      </p>
                      <ul className="accordion-list">
                        <li>
                          <p>
                            AI Platforms
                          </p>
                        </li>
                        <li>
                          <p>
                            User Account Registration & Authentication Services
                          </p>
                        </li>
                        <li>
                          <p>
                            Data Analytics Services
                          </p>
                        </li>
                      </ul>
                    </li><br />



                    <ul className="list-group list-group-flush mb-3">
                      <strong>
                        Your Rights
                      </strong><br />
                      <p>
                        You have rights under certain US state data protection laws. However, these rights are not absolute, and in certain cases, we may decline your request as permitted by law. These rights include:
                      </p>
                      <ul className="accordion-list">
                        <li>
                          <p>
                            <span className="fw-semibold">
                              Right to know
                            </span>{" "}
                            whether or not we are processing your personal data
                          </p>
                        </li>
                        <li>
                          <p>
                            <span className="fw-semibold">
                              Right to access
                            </span>{" "}
                            your personal data
                          </p>
                        </li>
                        <li>
                          <p>
                            <span className="fw-semibold">
                              Right to correct
                            </span>{" "}
                            inaccuracies in your personal data
                          </p>
                        </li>
                        <li>
                          <p>
                            <span className="fw-semibold">
                              Right to request
                            </span>{" "}
                            the deletion of your personal data

                          </p>
                        </li>
                        <li>
                          <p>
                            <span className="fw-semibold">
                              Right to obtain a copy
                            </span>{" "}
                            of the personal data you previously shared with us
                          </p>
                        </li>
                        <li>
                          <p>
                            <span className="fw-semibold">
                              Right to non-discrimination
                            </span>{" "}
                            for exercising your rights
                          </p>
                        </li>
                        <li>
                          <p>
                            <span className="fw-semibold">
                              Right to opt out
                            </span>{" "}
                            of the processing of your personal data if it is used for targeted advertising (or sharing as defined under California’s privacy law), the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects ("profiling")                          </p>
                        </li>
                        <br />
                      </ul>
                      <p>
                        Depending upon the state where you live, you may also have the following rights:
                      </p>
                      <ul className="accordion-list">
                        <li>
                          <p>
                            Right to access the categories of personal data being processed (as permitted by applicable law, including Minnesota’s privacy law)
                          </p>
                        </li>
                        <li>
                          <p>
                            Right to obtain a list of the categories of third parties to which we have disclosed personal data (as permitted by applicable law, including California's and Delaware's privacy law)
                          </p>
                        </li>
                        <li>
                          <p>
                            Right to obtain a list of specific third parties to which we have disclosed personal data (as permitted by applicable law, including Minnesota's and Oregon's privacy law)
                          </p>
                        </li>
                        <li>
                          <p>
                            Right to review, understand, question, and correct how personal data has been profiled (as permitted by applicable law, including Minnesota’s privacy law)
                          </p>
                        </li>
                        <li>

                          <p>
                            Right to limit use and disclosure of sensitive personal data (as permitted by applicable law, including California’s privacy law)

                          </p>
                        </li >

                        <li>
                          <p>
                            Right to opt out of the collection of sensitive data and personal data collected through the operation of a voice or facial recognition feature (as permitted by applicable law, including Florida’s privacy law)

                          </p>
                        </li>
                      </ul>
                    </ul>
                    <ul className="list-group list-group-flush mb-3">
                      <h6>
                        <strong>How to Exercise Your Rights</strong>
                      </h6>
                      <li className="list-group-item">
                        <p>
                          To exercise these rights, you can contact us by submitting
                          a <a href="/">data subject access request</a>, by emailing us at Info@veragoodrep.com,
                          or by referring to the contact details at the bottom of this document.
                        </p>

                        <p>
                          You can opt out from the selling of your personal information, targeted
                          advertising, or profiling by disabling cookies in Cookie Preference Settings.
                        </p>
                        <p>
                          Under certain US state data protection laws, you can designate an authorized
                          agent to make a request on your behalf. We may deny a request from an authorized
                          agent that does not submit proof that they have been validly authorized to act on
                          your behalf in accordance with applicable laws.
                        </p>
                      </li>
                    </ul>
                    <ul className="list-group list-group-flush mb-3">
                      <h6>
                        <strong>Request Verification</strong>
                      </h6>
                      <li className="list-group-item">
                        <p>
                          Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. We will only use personal information provided in your request to verify your identity or authority to make the request. However, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity and for security or fraud-prevention purposes.
                        </p>
                        <p>
                          If you submit the request through an authorized agent, we may need to collect additional information to verify your identity before processing your request and the agent will need to provide a written and signed permission from you to submit such request on your behalf.
                        </p>
                      </li>
                    </ul>
                    <ul className="list-group list-group-flush mb-3">
                      <h6>
                        <strong>Appeals</strong>
                      </h6>
                      <li className="list-group-item">
                        <p>
                          Under certain US state data protection laws, if we decline to take action regarding your request, you may appeal our decision by emailing us at Info@veragoodrep.com. We will inform you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions. If your appeal is denied, you may submit a complaint to your state attorney general.
                        </p>
                      </li>
                    </ul>
                    <ul className="list-group list-group-flush mb-3">
                      <h6>
                        <strong>California "Shine The Light" Law</strong>
                      </h6>
                      <li className="list-group-item">
                        <p>
                          California Civil Code Section 1798.83, also known as the "Shine The Light" law,
                          permits our users who are California residents to request and obtain from us,
                          once a year and free of charge, information about categories of personal information
                          (if any) we disclosed to third parties for direct marketing purposes and the names and
                          addresses of all third parties with which we shared personal information in the immediately
                          preceding calendar year. If you are a California resident and would like to make such a request,
                          please submit your request in writing to us by using the contact details provided in the section
                          <a href="/"> "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"</a>
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse11"
                    aria-expanded="false"
                    aria-controls="collapse11"
                  >
                    DO WE MAKE UPDATES TO THIS NOTICE?
                  </button>
                </h2>
                <div
                  id="collapse11"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <div className="accordion-body">
                      <ul className="list-group list-group-flush mb-3">
                        <li className="list-group-item">
                          <p className="fst-italic">
                            <span className="fw-semibold">In Short:</span>
                            {" "}Yes, we will update this notice as necessary to stay compliant with relevant laws.
                          </p>
                        </li>
                        <li className="list-group-item">
                          <p>
                            We may update this Privacy Notice from time to time. The updated version
                            will be indicated by an updated "Revised" date at the top of this Privacy
                            Notice. If we make material changes to this Privacy Notice, we may notify
                            you either by prominently posting a notice of such changes or by directly
                            sending you a notification. We encourage you to review this Privacy Notice
                            frequently to be informed of how we are protecting your information.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse12"
                    aria-expanded="false"
                    aria-controls="collapse12"
                  >
                    HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                  </button>
                </h2>
                <div
                  id="collapse12"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <ul className="list-group list-group-flush">
                      <p>
                        If you have questions or comments about this notice, you may email us at Info@veragoodrep.com or contact us by post at:
                      </p>
                      <li className="list-group-item">
                        <address>Vera, The Good Rep LLC</address>
                        <address>165 Cambridgepark Dr</address>
                        <address>Cambridge, MA 02140</address>
                        <address>United States</address>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse13"
                    aria-expanded="false"
                    aria-controls="collapse13"
                  >
                    HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
                  </button>
                </h2>
                <div
                  id="collapse13"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <p>
                          You have the right to request access to the personal information we collect
                          from you, details about how we have processed it, correct inaccuracies, or
                          delete your personal information. You may also have the right to withdraw your
                          consent to our processing of your personal information. These rights may be limited
                          in some circumstances by applicable law. To request to review, update, or delete your
                          personal information, please fill out and submit a <a href="/">data subject access request</a>.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main >

      <Footer />
    </div >
  );
}
