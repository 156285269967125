import React from "react";
import "./style.css";
import "./responsive.css";
import Header from "./Header";
import Footer from "./Footer";
import Check_img from '../assets/images/check-circle-1.png';
import Compare_Check from '../assets/images/compare-check.png';
import Compare_Wrong from '../assets/images/compare-wrong.png';
import Our_services from '../assets/images/bg-ourservices.png';

export default function Subscription() {
  return (
    <div>
      <Header />
      <main>
        <div className="section-layout section-subscription our-mission ">

          <div className="container">
            <div className="section-heading text-center z-1 text-dark">
              <h6 className="mb-2">Pricing</h6>
              {/* <h2>Collaborate with AI to generate content that resonates.</h2> */}
              <h2>Digital door knocking, lead creation and follow up has never been easier</h2>
            </div>
            <div className="row align-items-center subscription-plan">
              <div className="col-lg-3 mb-4 flex-fill align-self-stretch">
                <div className="card">
                  <div className="card-body p-0">
                    <div className="card-title">
                      <h6>ENTRY-LEVEL</h6>
                      <span>$8<span>/Lead</span></span>
                      <p>No Monthly Subscription</p>
                    </div>

                    <ul>
                      <li> <img src={Check_img} alt="" />Lead Pipeline access</li>
                      <li> <img src={Check_img} alt="" />In App “Hot Lead” Chat
                        functionality</li>
                      <li> <img src={Check_img} alt="" />Access to the Vera Store
                        to order marketing materials / door hangers for sale.</li>
                      <li> <img src={Check_img} alt="" />Customized QR code for
                        your lead generation, and social media use</li>
                      <li> <img src={Check_img} alt="" />Pay per Lead</li>
                    </ul>

                    {/* <a href="" className="btn border w-100 mt-auto">Purchase Now</a> */}
                  </div>
                </div>
              </div>

              <div className="col-lg-3 mb-4 flex-fill align-self-stretch">
                <div className="card">
                  <div className="card-body p-0">
                    <div className="card-title">
                      <h6>VETERAN REP</h6>
                      <span>$97<span>/Month</span></span>
                      <p>Monthly Subscription</p>

                    </div>

                    <ul>
                      <li> <img src={Check_img} alt="" />Everything in entry level, +</li>
                      <li> <img src={Check_img} alt="" />UNLIMITED Leads with subscription</li>
                      <li> <img src={Check_img} alt="" />Access to monthly targeted zip code mailer campaigns</li>
                    </ul>

                    {/* <a href="" className="btn border w-100 mt-auto">Purchase Now</a> */}
                  </div>

                  <span className="tag">Best Value</span>
                </div>
              </div>

              <div className="col-lg-3 mb-4 flex-fill align-self-stretch">
                <div className="card coming-soon">
                  <div className="card-body p-0">
                    <div className="card-title">
                      <h6>ELITE-PRO</h6>
                      <span><span></span></span>
                      <p>Yearly Subscription</p>

                    </div>

                    <ul>
                      <li> <img src={Check_img} alt="" />Everything in Veteran, +</li>
                      <li> <img src={Check_img} alt="" />AI follow up campaigns for existing leads</li>
                      <li> <img src={Check_img} alt="" />Add leads to your pipeline for drip marketing campaigns</li>
                      <li> <img src={Check_img} alt="" />24/7 Concierge support for interactive account progression assistance</li>
                    </ul>

                    {/* <a href="" className="btn border w-100 mt-auto">Purchase Now</a> */}
                  </div>
                  <span className="tag">Coming Soon</span>
                </div>
              </div>


            </div>
          </div>

        </div>

        <section>
          <div className="our-services price-compare">
            <div className="section-heading text-center z-1 text-dark">
              <h6 className="mb-2">Price compare</h6>
              <h2>Compare plans & features</h2>
            </div>

            <div className="container">
              <div className="row align-items-center">
                <div className="d-flex flex-column">
                  <div className="subscriptionplan-container subscriptionplan-header">
                    <div></div>
                    <div className="subscriptionplan-data">
                      <div className="p-1">
                        <h6 className="text-center">ENTRY-LEVEL</h6>
                      </div>
                      <div className="p-1">
                        <h6 className="text-center">VETERAN REP</h6>
                      </div>
                      <div className="p-1">
                        <h6 className="text-center">ELITE-PRO</h6>
                      </div>
                    </div>
                  </div>
                  <div className="subscriptionplan-container">
                    <div className="justify-content-start">
                      <h6>Lead Pipeline access</h6>
                    </div>
                    <div className="subscriptionplan-data">
                      <div>
                        <img src={Compare_Check} alt="" />
                      </div>
                      <div>
                        <img src={Compare_Check} alt="" />

                      </div>
                      <div>
                        <img src={Compare_Check} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="subscriptionplan-container">
                    <div className="justify-content-start">
                      <h6>In App “Hot Lead” Chat functionality</h6>
                    </div>
                    <div className="subscriptionplan-data">
                      <div>
                        <img src={Compare_Check} alt="" />
                      </div>
                      <div>
                        <img src={Compare_Check} alt="" />

                      </div>
                      <div>
                        <img src={Compare_Check} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="subscriptionplan-container">
                    <div className="justify-content-start">
                      <h6>Access to the Vera Store to order marketing materials / door hangers for scale.</h6>
                    </div>
                    <div className="subscriptionplan-data">
                      <div>
                        <img src={Compare_Check} alt="" />
                      </div>
                      <div>
                        <img src={Compare_Check} alt="" />

                      </div>
                      <div>
                        <img src={Compare_Check} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="subscriptionplan-container">
                    <div className="justify-content-start">
                      <h6>Customized QR code for your lead generation, and social media use</h6>
                    </div>
                    <div className="subscriptionplan-data">
                      <div>
                        <img src={Compare_Check} alt="" />
                      </div>
                      <div>
                        <img src={Compare_Check} alt="" />

                      </div>
                      <div>
                        <img src={Compare_Check} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="subscriptionplan-container">
                    <div className="justify-content-start">
                      <h6>Pay per Lead</h6>
                    </div>
                    <div className="subscriptionplan-data">
                      <div>
                        <img src={Compare_Check} alt="" />
                      </div>
                      <div>
                        <img src={Compare_Check} alt="" />

                      </div>
                      <div>
                        <img src={Compare_Check} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="subscriptionplan-container">
                    <div className="justify-content-start">
                      <h6>UNLIMITED Leads with subscription.</h6>
                    </div>
                    <div className="subscriptionplan-data">
                      <div>
                        <img src={Compare_Wrong} alt="" />
                      </div>
                      <div>
                        <img src={Compare_Check} alt="" />

                      </div>
                      <div>
                        <img src={Compare_Check} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="subscriptionplan-container">
                    <div className="justify-content-start">
                      <h6>Access to monthly targeted zip code mailer campaigns</h6>
                    </div>
                    <div className="subscriptionplan-data">
                      <div>
                        <img src={Compare_Wrong} alt="" />
                      </div>
                      <div>
                        <img src={Compare_Check} alt="" />

                      </div>
                      <div>
                        <img src={Compare_Check} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="subscriptionplan-container">
                    <div className="justify-content-start">
                      <h6>AI follow up campaigns for existing leads</h6>
                    </div>
                    <div className="subscriptionplan-data">
                      <div>
                        <img src={Compare_Wrong} alt="" />
                      </div>
                      <div>
                        <img src={Compare_Wrong} alt="" />

                      </div>
                      <div>
                        <img src={Compare_Check} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="subscriptionplan-container">
                    <div className="justify-content-start">
                      <h6>Add leads to your pipeline for drip marketing campaigns</h6>
                    </div>
                    <div className="subscriptionplan-data">
                      <div>
                        <img src={Compare_Wrong} alt="" />
                      </div>
                      <div>
                        <img src={Compare_Wrong} alt="" />

                      </div>
                      <div>
                        <img src={Compare_Check} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="subscriptionplan-container">
                    <div className="justify-content-start">
                      <h6>24/7 Concierge support for interactive account progression assistance.</h6>
                    </div>
                    <div className="subscriptionplan-data">
                      <div>
                        <img src={Compare_Wrong} alt="" />
                      </div>
                      <div>
                        <img src={Compare_Wrong} alt="" />

                      </div>
                      <div>
                        <img src={Compare_Check} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <img src={Our_services} alt="" className="bg-services d-none d-sm-block" />


          </div>
        </section>

        {/* Our Contact us starts */}
        <section id="contactus">
          <div className="section-layout contactus our-mission">
            <div className="section-heading text-center z-1 text-dark pt-0">
              <h6 className="mb-2">Feel free to contact us here!</h6>
            </div>

            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <h3 className="mb-4">We love to hear from our clients.</h3>
                  <a href="/" className="mb-4">
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex align-items-center">
                          <i className="fa-regular fa-envelope text-white"></i>
                          <span className="text-white ms-2">Email Us</span>
                        </div>
                        <p className="card-text text-white">
                          info@veragoodrep.com
                        </p>
                      </div>
                    </div>
                  </a>
                  <h3 className="mb-2">Address</h3>
                  <p className="mb-0">
                    165 Cambridgepark Dr, Cambridge, MA 02140
                  </p>
                </div>

                <div className="col-md-6">
                  <div className="contact-map h-100">
                    <iframe
                      title="map"
                      className="w-100 h-100"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2946.5244852181413!2d-71.14932762339586!3d42.39527533287524!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e377a7db6d532d%3A0x75f3797fe7607b1f!2s165%20Cambridgepark%20Dr%2C%20Cambridge%2C%20MA%2002140%2C%20USA!5e0!3m2!1sen!2sin!4v1725629804277!5m2!1sen!2sin"
                      allowFullScreen
                      loading="lazy"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Our Contact us ends */}

      </main>
      <Footer />
    </div>
  );
}
