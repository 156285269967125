import React, { useState, useEffect } from "react";
import "./style.css";
import "./responsive.css";
import Logo from "../assets/images/logo.png";
import { HashLink } from "react-router-hash-link";
import { useLocation } from "react-router-dom"; // Import useLocation

export default function Header() {
  const location = useLocation(); // Get the current location
  const [activeLink, setActiveLink] = useState('/');

  useEffect(() => {
    // Set active link based on the current pathname and hash
    const currentPath = location.hash || location.pathname;
    setActiveLink(currentPath);

    // Scroll to the section if there's a hash in the URL
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1)); // Remove the '#' from the hash
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  const handleClick = (path) => {
    setActiveLink(path);
  };

  const handleSubscribeClick = () => {
    setActiveLink('/subscription');
  };

  return (
    <header className="mainheader">
      <div className="header-lower">
        <div className="container">
          <nav className="navbar navbar-expand-lg">
            <div className="container-fluid px-0">
              <a className="navbar-brand" href="/">
                <img
                  src={Logo}
                  alt="img"
                  title=""
                  className="headerlogo shadow"
                />
              </a>

              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              <div className="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
                <ul className="navbar-nav mb-2 mb-lg-0 gap-0 gap-lg-3 pt-3 pt-lg-0">
                  <li className="nav-item">
                    <a
                      className={`nav-link ${activeLink === '/' ? 'active' : ''}`}
                      aria-current="page"
                      href="/"
                      onClick={() => handleClick('/')}
                    >
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <HashLink
                      className={`nav-link ${activeLink === '#aboutus' ? 'active' : ''}`}
                      smooth to="/#aboutus"
                      onClick={() => handleClick('#aboutus')}
                    >
                      About us
                    </HashLink>
                  </li>
                  <li className="nav-item">
                    <HashLink
                      className={`nav-link ${activeLink === '#contactus' ? 'active' : ''}`}
                      smooth to="/#contactus"
                      onClick={() => handleClick('#contactus')}
                    >
                      Contact us
                    </HashLink>
                  </li>
                </ul>

                <a
                  href="/subscription"
                  className="btn btn-login d-block d-lg-none mb-lg-0 mb-0"
                  onClick={handleSubscribeClick}
                >
                  Pricing 
                </a>

              </div>
              <a
                href="/subscription"
                className="btn btn-login d-none d-lg-block"
                onClick={handleSubscribeClick}
              >
                Pricing
              </a>
            </div>

          </nav>
        </div>
      </div>
    </header>
  );
}