import Header from "./Header";
import Footer from "./Footer";
import hero from "../assets/images/heroimage-2.png";
import hero1 from "../assets/images/heroimage-3.png";
import hero2 from "../assets/images/star.png";
import googlestore from "../assets/images/googlestore.png";
import appstore from "../assets/images/appstore.png";
import heroshield from "../assets/images/heroshield.png";
import herochat from "../assets/images/herochat.png";
import herobottom from "../assets/images/herobottom.png";
import heroimage from "../assets/images/heroimage.png";
import imagetext from "../assets/images/image-text-1.png";
import imagetext2 from "../assets/images/image-text-2.png";
import services from "../assets/images/services-img.png";
import ourservices from "../assets/images/bg-ourservices.png";
import mission from "../assets/images/mission-image.png";
import aboutus1 from "../assets/images/aboutus-image-1.png";
import aboutus2 from "../assets/images/bg-aboutus-3.png";
import herobottom1 from "../assets/images/herobottom.png";
import heroimage1 from "../assets/images/heroimage.png";
import image01 from "../assets/images/image-text-1.png";
import image02 from "../assets/images/image-text-2.png";
import Video from '../assets/video/aura.mp4';


const Home = () => {
  return (
    <>
      <Header />
      {/* hero section starts */}
      <section id="home">
        <div className="hero-section">
          <img src={hero} alt="hero" className="herorect img-fluid" />
          <img
            src={hero1}
            alt="hero-image"
            className="heroshape z-0 img-fluid"
          />

          <div className="container z-1">
            <div className="row align-items-center">
              <div className="col-lg-6 order-2 order-lg-1 pt-5 pt-lg-0">
                <img src={hero2} alt="" className="mb-2 img-fluid" />
                <h1>We are a Value first organization.</h1>
                <p>
                  Vera was designed with our client-first approach at the heart of our operation.
                </p>
                <div className="d-flex align-items-center flex-column flex-sm-row gap-3 gap-sm-2">
                  <a href="https://apps.apple.com/us/app/vera-the-good-rep/id6733241906"
                     target="_blank" 
                     rel="noopener noreferrer">
                    <img src={appstore} alt="app-store" className="img-fluid" />
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.veragoodrep&hl=en_US" 
                   target="_blank" 
                   rel="noopener noreferrer">
                    <img
                      src={googlestore}
                      alt="google-store"
                      className="img-fluid"
                    />
                  </a>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2  pb-0 pb-sm-5 pb-lg-0">
                <div className="d-flex align-items-center justify-content-center">
                  <div className="startchat">
                    <div className="heroshield">
                      <div className="heroicon">
                        <img
                          src={heroshield}
                          alt="heroshield"
                          className="img-fluid"
                        />
                      </div>
                    </div>

                    <div className="herochat">
                      <div className="heroicon">
                        <img
                          src={herochat}
                          alt="herochat"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <img
                      src={herobottom}
                      alt="herobottom"
                      className="herobottom img-fluid"
                    />

                    <div className="heroimage">
                      <div className="heroimage-container">
                        <img
                          src={heroimage}
                          alt="heroimage"
                          className="img-fluid"
                        />

                        <img
                          src={imagetext}
                          alt="image-text"
                          className="imagetext1 img-fluid"
                        />
                        <img
                          src={imagetext2}
                          alt="image-text"
                          className="imagetext2 img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* hero section ends */}

      {/* Our Services starts */}
      <section>
        <div className="our-services">
          <div className="section-heading text-center z-1 text-dark ">
            <h6 className="mb-2">Our Services</h6>
            {/* <h2>Here are Our Values</h2> */}

            {/* Our Video starts */}
            <div class="intro-video">
              {/* <div class="section-layout intro-video "> */}
              <video autoPlay loop muted controls name="media">
                <source src={Video} type="video/mp4" />
              </video>
            </div>
            {/* Our Video end */}
          </div>

          <div className="section-heading text-center z-1 text-dark ">
            <h2>Here are Our Values</h2>

          </div>

          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-5 pb-5 pb-lg-0">
                <div className="ourservices-tagline">
                  <img src={services} alt="services" className="img-fluid" />

                  <span style={{ "--position-i": 1, color: "#6A1B9A" }}>
                    Communication
                  </span>
                  <span style={{ "--position-i": 2, color: "#00695C" }}>
                    Integrity
                  </span>
                  <span style={{ "--position-i": 3, color: "#EF6C00" }}>
                    Transparency
                  </span>
                  <span style={{ "--position-i": 4, color: "#558B2F" }}>
                    Excellence
                  </span>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="container">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="card">
                        <div className="card-body">
                          <div className="cardicon">
                            <i className="fa-solid fa-comments"></i>
                          </div>
                          <h5>Communication</h5>
                          <p>
                            We strive to execute a high level of excellence
                            while delivering premium communication and
                            information in a transparent and mindful platform.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="card">
                        <div className="card-body">
                          <div className="cardicon">
                            <i className="fa-solid fa-comments"></i>
                          </div>
                          <h5>Integrity</h5>
                          <p>
                            By being honest, consistent, accountable, and
                            ethical, you can build strong, trusting
                            relationships that benefit both you and your
                            clients.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="card">
                        <div className="card-body">
                          <div className="cardicon">
                            <i className="fa-solid fa-comments"></i>
                          </div>
                          <h5>Transparency</h5>
                          <p>
                            By fostering an environment of openness and honesty,
                            you can build trust, enhance customer satisfaction,
                            and ultimately drive loyalty.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="card">
                        <div className="card-body">
                          <div className="cardicon">
                            <i className="fa-solid fa-comments"></i>
                          </div>
                          <h5>Excellence</h5>
                          <p>
                            By understanding client needs, delivering consistent
                            quality, communicating proactively, personalizing
                            service, and providing responsive support, you can
                            build strong and lasting client relationships.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src={ourservices}
            alt="ourservices"
            className="bg-services img-fluid d-none d-sm-block"
          />
        </div>
      </section>
      {/* Our Services ends */}

      {/* Our Mission starts */}
      <section>
        <div className="section-layout our-mission">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 order-2 order-lg-1">
                <h3 className="mb-3">Our Mission</h3>
                <p className="mb-2">
                  Our mission is to bring a high sense of integrity to the
                  industries we work in, which have prime opportunities to
                  positively impact consumers and our nation, through
                  collaboration.
                </p>
                <p className="mb-2">
                  We strive to execute a high level of excellence while
                  delivering premium communication and information in a
                  transparent and mindful platform. VERA is committed to
                  providing VALUE, EXCELLENCE, RAPPORT and ACCURACY to our
                  clients and to be able to provide a platform to easily provide
                  this to consumers for our partners.
                </p>
                {/* <p className="mb-2">
                  Are you interested in learning about solar energy for your
                  home, but don’t want to have to get all of your questions
                  answered by a random sales person.
                </p> */}
              </div>

              <div className="col-lg-5 offset-lg-1 order-1 order-lg-2 pb-5 pb-lg-0">
                <div className="startchat mx-auto">
                  <div className="heroimage">
                    <div className="heroimage-container missiontext">
                      <img src={mission} alt="mission" className="img-fluid" />
                      <span style={{ "--position-i": 1, color: "#6A1B9A" }}>
                        Value
                      </span>
                      <span style={{ "--position-i": 2, color: "#00695C" }}>
                        Excellence
                      </span>
                      <span style={{ "--position-i": 3, color: "#EF6C00" }}>
                        Rapport
                      </span>
                      <span style={{ "--position-i": 4, color: "#558B2F" }}>
                        Accuracy
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Our Mission ends */}

      {/* Our aboutus starts  */}
      <section id="aboutus" >
        <div className="section-layout about-us p-0">
          <div className="section-heading text-center z-1 text-dark">
            <h6 className="mb-2">About us</h6>
            <h2>Our Journey Began</h2>
          </div>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-5 pb-5 pb-lg-0">
                <div className="aboutus-image">
                  <img src={aboutus1} alt="aboutus" className="img-fluid" />
                </div>
              </div>

              <div className="col-lg-6 offset-lg-1">
                <h3 className="mb-3">Who we are</h3>
                <p className="mb-2">
                  Vera was developed by a team that was all too aware of the
                  negative impacts of the “rogue” salesperson; misinformation
                  can ruin an industry. We felt strongly that solar is an
                  industry that can positively impact the world, so we decided
                  to make a change.
                </p>
              </div>
            </div>
          </div>
          <img
            src={aboutus2}
            alt="bg-aboutus"
            className="bg-services img-fluid"
          />
        </div>
      </section>
      {/* Our aboutus ends */}


      {/* Our Goal starts */}
      <section>
        <div className="section-layout our-mission our-goal">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 order-2 order-lg-1">
                <h3 className="mb-3">Our Goal</h3>
                <p className="mb-2">
                  Our goal is to provide up front, transparent information for
                  clients so they can make informed decisions for their homes.
                  We hope that you will find our approach unique and refreshing.
                </p>
                <p className="mb-2">
                  We use the innovation of AI to deliver the information, that
                  has been trained by all of the best minds of the solar
                  industry to deliver one solid database of upfront information
                  to our clients.
                </p>
              </div>

              <div className="col-lg-5 offset-lg-1 order-1 order-lg-2 pb-5 pb-lg-0">
                <div className="d-flex align-items-center justify-content-center">
                  <div className="startchat">
                    <img
                      src={herobottom1}
                      alt="herobottom"
                      className="herobottom img-fluid"
                    />
                    <div className="heroimage">
                      <div className="heroimage-container">
                        <img
                          src={heroimage1}
                          alt="heroimage"
                          className="img-fluid"
                        />

                        <img
                          src={image01}
                          alt="image-text"
                          className="imagetext1 img-fluid"
                        />
                        <img
                          src={image02}
                          alt="image-text"
                          className="imagetext2 img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Our Goal ends */}

      {/* Our Contact us starts */}
      <section id="contactus">
        <div className="section-layout contactus our-mission">
          <div className="section-heading text-center z-1 text-dark pt-0">
            <h6 className="mb-2">Feel free to contact us here!</h6>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h3 className="mb-4">We love to hear from our clients.</h3>
                <a href="/" className="mb-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <i className="fa-regular fa-envelope text-white"></i>
                        <span className="text-white ms-2">Email Us</span>
                      </div>
                      <p className="card-text text-white">
                        info@veragoodrep.com
                      </p>
                    </div>
                  </div>
                </a>
                <h3 className="mb-2">Address</h3>
                <p className="mb-0">
                  165 Cambridgepark Dr, Cambridge, MA 02140
                </p>
              </div>

              <div className="col-md-6">
                <div className="contact-map h-100">
                  <iframe
                    title="map"
                    className="w-100 h-100"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2946.5244852181413!2d-71.14932762339586!3d42.39527533287524!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e377a7db6d532d%3A0x75f3797fe7607b1f!2s165%20Cambridgepark%20Dr%2C%20Cambridge%2C%20MA%2002140%2C%20USA!5e0!3m2!1sen!2sin!4v1725629804277!5m2!1sen!2sin"
                    allowFullScreen
                    loading="lazy"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Our Contact us ends */}
      <Footer />
    </>
  );
};

export default Home;
